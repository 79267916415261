<template>
  <fragment>
    <slot
      :userNameTitleAccordingToRole="userNameTitleAccordingToRole"
      :userEmail="userEmail"
      :userProjectTeamRole="userProjectTeamRole">
      <div>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <p
              class="medium-p font-weight-medium mb-0 user-info-col"
              v-on="on">
              <span>
                {{ userNameTitleAccordingToRole }}
              </span>
              <template v-if="userProjectTeamRole">
                <span class="subtitle-2">{{ userProjectTeamRole }}</span>
              </template>
            </p>
            <p
              class="small-p title-in-modal mb-0">
              {{ userEmail }}
            </p>
          </template>
          <span>{{ userNameTitleAccordingToRole }} {{ userProjectTeamRole || '' }}</span>
        </v-tooltip>
      </div>
    </slot>
  </fragment>
</template>
<script>
import { extractUserTeamRole } from '@/utils';
export default {
  name: 'AppShareModalUserInfo',
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    userName() {
      return this.userInfo?.name;
    },
    userNameTitleAccordingToRole() {
      const { userProjectTeamRole: role, userName: name } = this;
      if (!role) return name;
      return `${name},`;
    },
    userEmail() {
      return this.userInfo?.email || '---';
    },
    userProjectTeamRole() {
      const { role } = extractUserTeamRole(this.userInfo?.projectTeamRole);
      return role;
    },
  },
};
</script>
<style lang="scss">
.user-info-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: 721px) {
    max-width: 400px;
  }
  @media (max-width: 720px) {
    max-width: 200px;
  }
}
</style>
